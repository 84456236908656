import React, { useState, useEffect } from 'react';
import Heading from '../../commoncomponents/Heading/Heading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Events.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
// import required modules

const AllEvents = () => {
  const [allEvent, setAllEvent] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    event_id: null,
    participant_name: '',
    participant_email: '',
    participant_phone_number: '',
    isStartup: false,
    participant_startup_domain: '',
    participant_org_designation: '',
  });

  const initialFormData = {
    event_id: null,
    participant_name: '',
    participant_email: '',
    participant_phone_number: '',
    isStartup: false,
    participant_startup_domain: '',
    participant_org_designation: '',
  };

  const getEventApi = async () => {
    console.log(`${apiBaseUrl}/event`)
    try {
      const result = await fetch(`${apiBaseUrl}/event`);
      const finalEvent = await result.json();
      setAllEvent(finalEvent.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEventApi();
  }, []);

  const filteredEvents = allEvent.filter(event => !event.isArchived);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Form data successfully submitted:', data);

      // Show toast notification on successful registration
      toast.success(data.message);
      setShowModal(false);

      // Clear the form fields
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error submitting form data:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const breakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  };

  return (
    <>
      <div className='container'>
        <div className='py-5'>
          <Heading title='All Events' />
        </div>

        {loading ? (
          <p>Loading events...</p>
        ) : (
          <Swiper
            breakpoints={breakpoints}
            slidesPerView={3}
            spaceBetween={30}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {filteredEvents.map((event, index) => (
              <SwiperSlide key={index}>
                <div className='event-card'>
                  <img
                    src={`http://s3.springuplabs.in/${event.event_image}`}
                    alt={event.event_title}
                    className='all-event-img'
                  />
                  <h5 className='event-card-heading mt-3'>{event.event_title}</h5>
                  <p className='event-card-text'>{event.event_description}</p>
                  <p className='event-card-text'>
                    <strong>Location:</strong> {event.event_location}
                  </p>
                  <p className='event-card-text'>
                    <strong>Date:</strong> {event.event_date}
                  </p>
                  <p className='event-card-text'>
                    <strong>Time:</strong> {event.event_time} - {event.event_endTime}
                  </p>
                  <div>
                    <button
                      className='btn btn-primary'
                      onClick={() => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          event_id: event.id,
                        }));
                        setShowModal(true);
                      }}
                    >
                      Register Now
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            {/* Add custom navigation buttons */}
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </Swiper>
        )}
      </div>

      {showModal && (
        <div className='custom-modal-overlay'>
          <div className='custom-modal-content'>
            <button
              className='custom-modal-close'
              onClick={() => setShowModal(false)}
            >
              &times;
            </button>
            <h5 className='modal-title'>Register for Event</h5>
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <label>Name</label>
                <input
                  type='text'
                  className='form-control mt-2'
                  name='participant_name'
                  placeholder='Enter your name'
                  value={formData.participant_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='mb-3'>
                <label>Email</label>
                <input
                  type='email'
                  className='form-control mt-2'
                  name='participant_email'
                  placeholder='Enter your email'
                  value={formData.participant_email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='mb-3'>
                <label>Phone Number</label>
                <input
                  type='text'
                  className='form-control mt-2'
                  name='participant_phone_number'
                  placeholder='Enter your phone number'
                  value={formData.participant_phone_number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='mb-3'>
                <label>Are you a startup?</label>
                <input
                  type='checkbox'
                  className='form-check-input mt-2 ms-2'
                  name='isStartup'
                  checked={formData.isStartup}
                  onChange={handleChange}
                />
              </div>
              <div className='mb-3'>
                <label>Startup Domain</label>
                <input
                  type='text'
                  className='form-control mt-2'
                  name='participant_startup_domain'
                  placeholder='Enter your startup domain'
                  value={formData.participant_startup_domain}
                  onChange={handleChange}
                />
              </div>
              <div className='mb-3'>
                <label>Designation</label>
                <input
                  type='text'
                  className='form-control mt-2'
                  name='participant_org_designation'
                  placeholder='Enter your designation'
                  value={formData.participant_org_designation}
                />
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button type='submit' className='btn btn-primary'>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AllEvents;
