import React, { useState } from 'react'
import './FacilitySpace.css'
import bgImg from '../../assets/images/space/image-1.png'
import Heading from '../../commoncomponents/Heading/Heading'
import centerImg1 from '../../assets/images/space/pic-1.png'
import centerImg2 from '../../assets/images/space/pic-2.png'
import centerImg3 from '../../assets/images/space/pic-3.png'
import centerImg4 from '../../assets/images/space/pics4.jpg'

import bgImg2 from '../../assets/images/space/bg-image-1.svg'
import ArrowBtn from '../../commoncomponents/buttons/ArrowBtn'
import checkImg from '../../assets/images/check.png'
import Ourcenterform from './Ourcenterform'
import VirtualAddform from './VirtualAddform'
import BeCenterform from './BeCenterform'
export const features = [
  "Complete Security with CCTV Surveillance",
  "24*7 Access to the Space/365 Days",
  "Reception Facility on the Ground floor",
  "Beautifully designed Co-working space",
  "Access to consultants & mentors",
  "Conference, meeting room and rooftop cafeteria",
  "On-site managers & errand boys to help with any space-related task",
  "Electricity with 100% power backup",
  "Unlimited Tea & Coffee",
  "Access to the Global Community of Ideas to Impacts Hub",
  "A vibrant community of Diverse & inspiring peers",
  "Enriching professional and social events"
];
const FacilitySpace = () => {
  const [showModal, setShowModal] = useState(false);
  const [virtualModal, setVirtualModal] = useState(false);
  const [beCenterModal, setBeCenterModal] =useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenVirtualModal = () => {
    setVirtualModal(true);
  };

  const handleCloseVirtualModal = () => {
    setVirtualModal(false);
  };
  const handleOpenVirtualbecenterModal = () => {
    setBeCenterModal(true);
  };

  const handleCloseVirtualbecenterModal = () => {
    setBeCenterModal(false);
  };
  return (
    <>
      <div className='container paddings'>
        <div className='row flex-wrap-reverse'>
          <div className='col-md-6'>
            <img src={bgImg} className='img-space' alt="Facility" />
          </div>
          <div className='col-md-6 d-flex justify-content-center align-items-center text-center'>
            <h1 className="facility-heading">Bringing our <br /> <span className='spaceHeding'>Facilities to Spaces</span></h1>
          </div>
        </div>
      </div>
      <div className='container-fluid bg-container py-5'>
        <Heading title="Our Centers" />
        <div className='container'>
          <div className='row row-cols-1 row-cols-md-4 g-5 py-5'>
            <div className="col">
              <div className="card-space">
                <img src={centerImg1} className="card-img-space" alt="centerImg" />
                <div className="card-body">
                  <h5 className="facilities-card-title">Baner HQ</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card-space">
                <img src={centerImg2} className="card-img-space" alt="centerImg" />
                <div className="card-body">
                  <h5 className="facilities-card-title">Pan Card Club Road</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card-space">
                <img src={centerImg3} className="card-img-space" alt="centerImg" />
                <div className="card-body">
                  <h5 className="facilities-card-title">Wakad</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card-space">
                <img src={centerImg4} className="card-img-space" alt="centerImg" />
                <div className="card-body">
                  <h5 className="facilities-card-title">Viman Nagar</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              onClick={handleOpenModal}
              style={{
                fontFamily: '"Inter Tight", sans-serif',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#F59E0B',
                border: 'none',
                borderRadius: '9999px',
                fontWeight: 'bold',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                width: '160px'
              }}
            >Apply Now
              <span style={{ fontSize: '30px' }}>  &#8594;</span>
            </button>
          </div>
        </div>
      </div>
      <Ourcenterform show={showModal} onClose={handleCloseModal} />
      <div className='container py-5'>
        <div className='row'>
          <div className='col-md-6 p-2'>
            <h1 className='centerText'>Turn Facility into Co-working</h1>
            <h3 className='becenter-text'>Be a Franchise owner of Ideas to Impacts Hub</h3>
            <p className='pTextCenter'>Ideas to Impacts Hub believes in growing by accelerating the nations growth. We are now in the process of extending our presence across various locations in India. Through a revenue-sharing arrangement, we collaborate with real estate partners or facility owners to establish and operate the I2I Hub coworking model.</p>
            <p className='pTextCenter'>Our Baner HQ stands tall behind the recent successful model implementation at two centers in Pune city as we grew confident to replicate it at multiple other venues. By partnering with us, you have the opportunity to explore investment possibilities in this venture and become an integral part of the thriving startup ecosystem.</p>
            <button
              onClick={handleOpenVirtualbecenterModal}
              style={{
                fontFamily: '"Inter Tight", sans-serif',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#F59E0B',
                border: 'none',
                borderRadius: '9999px',
                fontWeight: 'bold',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                width: '160px'
              }}
            >Know More
              <span style={{ fontSize: '30px' }}>  &#8594;</span>
            </button>
              <BeCenterform show={beCenterModal} onClose={handleCloseVirtualbecenterModal} />
          </div>
          <div className='col-md-6 full-screen-bg'>
            <h2 className='space-list-heading'>All our centers are primmed with facilities</h2>
            {features.map((feature, index) => (
              <div className='d-flex '>
                <img src={checkImg} alt='check' className='img-check' />
                <p key={index} className='ms-2 space-list'>{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='container-fluid py-5'>
        <div className='bg-image-facility'>
          <div className='container'>
            <div className='overlay-heading-facility'>
              <h2 className='virtual-heading'>Virtual Address</h2>
              <h3 className='virtual-heading-2'>I2I redefines your business presence with boundless opportunities through virtual nexus</h3>
              <p className='space-list'>Need an incubator's support from home?</p>
              <p className='space-list'>Ideas to impacts hub offers virtual address so you can establish a professional business presence without the constraints of a physical location. Seamlessly connect with clients, investors, and partners from anywhere, while enjoying the benefits of a prestigious address. Join our community of forward-thinking entrepreneurs and leverage the flexibility and convenience of a virtual workspace to propel your business to new heights. Experience the future of work with our cutting-edge virtual incubator.</p>
              <button
                onClick={handleOpenVirtualModal}
                style={{
                  fontFamily: '"Inter Tight", sans-serif',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#F59E0B',
                  border: 'none',
                  borderRadius: '9999px',
                  fontWeight: 'bold',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  width: '160px'
                }}
              >Apply Now
                <span style={{ fontSize: '30px' }}>  &#8594;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <VirtualAddform show={virtualModal} onClose={handleCloseVirtualModal} />
    </>
  )
}
export default FacilitySpace;