import React, { useState } from "react";
import "./Events.css";
import aboutImg from "../../assets/images/events-page/image-1.png";
import ArrowBtn from "../../commoncomponents/buttons/ArrowBtn";
import Products from "./Products";
import Speakers from "./Speakers";
import Partners from '../../components/Home/Partners'
import PastEvent from "./PastEvent";
import CountUp from 'react-countup';
import AllEvents from "./AllEvents";
import HostEventform from "./HostEventform";
import Growbrandform from "./Growbrandform";
const Events = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalGrow, setShowModalGrow] = useState(false);


  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModalGrow = () => {
    setShowModalGrow(true);
  };

  const handleCloseModalGrow = () => {
    setShowModalGrow(false);
  };
  return (
    <>
      <div className="container-fluid event-container">
        <div className="couter-row">
          <h1 className="event-heading">Building the Largest Startup Ecosystem</h1>
          <div className="row w-50 mx-auto py-5">
            <div className="col-md-4">
              <div>
                <h2 className="count-heading">
                  <CountUp end={200} />+
                </h2>
                <p className="count-text-1">Events</p>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <h2 className="count-heading">
                  <CountUp end={500} />+
                </h2>
                <p className="count-text-1">Speakers</p>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <h2 className="count-heading">15000+</h2>
                <p className="count-text-1">Participants</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AllEvents />
      <div className="container py-5">
        <div className="row g-4">
          <div className="col-md-6">
            <img src={aboutImg} alt="event" className="event-img-about" />
          </div>
          <div className="col-md-6">
            <div>
              <h1 className="event-heading">Host an event</h1>
              <p className="count-text-1">Grow your network by utilizing ours</p>
              <p className="count-text-1">Ideas to Impacts Hub aims to build the startup ecosystem and expose multiple avenues to help them grow holistically.</p>
              <p className="count-text-1">We are a firm believer in fostering the growth of the startup ecosystem and providing opportunities for comprehensive development. Join us to host and collaborate in organizing events at our venue to become an integral part of the ecosystem.</p>
              <p className="count-text-1">To become a co-host for an event</p>
              <div className="">
                <button
                  onClick={handleOpenModal}
                  style={{
                    fontFamily: '"Inter Tight", sans-serif',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F59E0B',
                    border: 'none',
                    borderRadius: '9999px',
                    fontWeight: 'bold',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    width: '160px'
                  }}
                >Apply Now
                  <span style={{ fontSize: '30px' }}>  &#8594;</span>
                </button>
                <HostEventform show={showModal} onClose={handleCloseModal} />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4 mt-4">

          <div className="col-md-6">
            <div className="mt-3">
              <h1 className="event-heading">Grow your brand with Events</h1>
              <p className="count-text-1">Do you want to grow your brand awareness among innovative minds?</p>
              <p className="count-text-1" >If you wish to be exposed to such bright pool of audience to create brand awareness or organise specifically curated events</p>
              <p className="count-text-1">To start planning with us now!</p>

              <div>
                <button
                  onClick={handleOpenModalGrow}
                  style={{
                    fontFamily: '"Inter Tight", sans-serif',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F59E0B',
                    border: 'none',
                    borderRadius: '9999px',
                    fontWeight: 'bold',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    width: '160px'
                  }}
                >Apply Now
                  <span style={{ fontSize: '30px' }}>  &#8594;</span>
                </button>
                <Growbrandform show={showModalGrow} onClose={handleCloseModalGrow} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img src={aboutImg} alt="event" className="event-img-about" />
          </div>
        </div>
      </div>

      <Products />
      <Speakers />
      <Partners />
      <PastEvent />
    </>
  );
};
export default Events;
