import React from 'react';
import Heading from '../../commoncomponents/Heading/Heading';
import Pimg1 from '../../assets/images/home/partners/Picture1.jpg'
import Pimg2 from '../../assets/images/home/partners/Picture2.png'
import Pimg3 from '../../assets/images/home/partners/Picture3.jpg'
import Pimg4 from '../../assets/images/home/partners/Picture4.jpg'
import Pimg5 from '../../assets/images/home/partners/Picture6.jpg'
import Pimg6 from '../../assets/images/home/partners/Picture7.jpg'
import Pimg7 from '../../assets/images/home/partners/Picture8.jpg'
import Pimg8 from '../../assets/images/home/partners/Picture9.jpg'
import Pimg9 from '../../assets/images/home/partners/Picture10.png'
import Pimg10 from '../../assets/images/home/partners/Picture11.png'
import Pimg11 from '../../assets/images/home/partners/Picture12.png'
import Pimg12 from '../../assets/images/home/partners/Picture13.png'
import Pimg13 from '../../assets/images/home/partners/Picture14.png'
import Pimg14 from '../../assets/images/home/partners/Picture15.png'
import Pimg15 from '../../assets/images/home/partners/Picture16.png'
import Pimg16 from '../../assets/images/home/partners/Picture17.png'
import Pimg17 from '../../assets/images/home/partners/Picture18.gif'
import Pimg18 from '../../assets/images/home/partners/Picture19.png'
import Pimg19 from '../../assets/images/home/partners/Picture20.jpg'
import Pimg20 from '../../assets/images/home/partners/Picture21.png'
const members = [
    Pimg1, Pimg2, Pimg3, Pimg4,  
    Pimg9, Pimg10, Pimg11, Pimg12, Pimg13, Pimg14, Pimg15, 
    Pimg16,Pimg5, Pimg6, Pimg7, Pimg8, Pimg17, Pimg18, Pimg19, Pimg20
];

const Members = () => {
    return (
        <>
            <div className='py-5'>
                <Heading title="Members" />
            </div>
            <div className='container-fluid member-container'>
                <div className='container py-5'>
                <div className="row g-4 py-5">
                        {members.map((memberImg, index) => (
                            <div className="col-6 col-sm-4 col-md-3" key={index}>
                                <div className='member-card'>
                                    <img src={memberImg} alt={`member ${index + 1}`} className='img-member-all' />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Members;
