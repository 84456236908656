import React from 'react'
import probleImg from '../../assets/images/corporate/image-2.png'
import Heading from '../../commoncomponents/Heading/Heading';
import ProgramImg1 from '../../assets/images/corporate/image-4.svg';
import ProgramImg2 from '../../assets/images/corporate/image-5.svg';
import ProgramImg3 from '../../assets/images/corporate/image-6.svg';
import ProgramImg4 from '../../assets/images/corporate/image-7.svg';
import './Corporate.css'
const CorporateInnovation = () => {
  return (
    <>
    <div className='py-5 text-center'>
        <Heading title="Corporate Innovation Program" />
        <p className='Innovation-heading-text'>Let us help you look for the hidden gems that fit your crown</p>
    </div>

    <div className='container-fluid'>
    <div className='row no-gutters'>
      <div className='col-md-6 first-bg-1 d-flex align-items-center justify-content-center p-0' >
        <div>
        <h1 className="Innovation-heading">Hackathon</h1>
        <p className='Innovation-heading-text'>Hack your way to victory</p>
        <ul className='innovation-list'>
            <li >We are known for conducting Hackathons with early-stage startups and students on problem statements.</li>
            <li>Our network helps to outreach with institutes and other stakeholders.</li>
            <li>We provide tech platform and operational support through mentors, jury, and evaluators.</li>
        </ul>
        </div>
      </div>
      <div className='col-md-6 p-0'>
        <img src={ProgramImg1} alt='p-img' className='img-bg-corporate' />
      </div>
    </div>
    </div>

    <div className='container-fluid'>
    <div className='row no-gutters'>
      <div className='col-md-6 p-0'>
        <img src={ProgramImg2} alt='p-img' className='img-bg-corporate' />
      </div>
      <div className='col-md-6 first-bg-2 d-flex align-items-center justify-content-center p-0' >
        <div>
        <h1 className="Innovation-heading">Innovation garage</h1>
        <p className='Innovation-heading-text'>Automate your company's innovation with us</p>
        <ul className='innovation-list'>
           <li>We will set up an incubation & accelerator program inside your campus.</li>
           <li>Our specialists find & nurture early-stage startups to co-create solutions with the corporate.</li>
           <li>We maintain monthly reports and analytics on startups and innovations around given problem statements.</li>
           <li>Our dedicated event team hosts meetups, product pitches, and events around the required solution.</li>
             </ul>
        </div>
      </div>
    </div>
    </div>

    <div className='container-fluid'>
    <div className='row no-gutters'>
     
      <div className='col-md-6 first-bg-3 d-flex align-items-center justify-content-center p-0' >
        <div>
        <h1 className="Innovation-heading">Innovation day</h1>
        <p className='Innovation-heading-text'>Source the best of bests with Ideas to Impacts Hub</p>
        <ul className='innovation-list'>
            <li>Our well-built ecosystem helps to host Annual <br/>Innovation Day for the corporate with Industry leaders and Startups.</li>
            <li>We hold expertise in curating innovative startups<br/> aligning with the core business of the corporate during a day-long program.</li>
            <li>We provide collaboration between leadership from various departments with startups as vendors.</li>
        </ul>
        </div>
      </div>
      <div className='col-md-6 p-0'>
        <img src={ProgramImg3} alt='p-img' className='img-bg-corporate' />
      </div>
    </div>
    </div>
    
    <div className='container-fluid'>
    <div className='row no-gutters'>
    <div className='col-md-6 p-0'>
        <img src={ProgramImg4} alt='p-img' className='img-bg-corporate' />
      </div>
      <div className='col-md-6 first-bg-4 d-flex align-items-center justify-content-center' >
        <div className='w-75'>
        <h1 className="Innovation-heading">Startup Scouting</h1>
        <p className='Innovation-heading-text'>Great things are built by a series of small things brought together</p>
       <ul className='innovation-list'>
        <li>Ideas to Impacts Hub acts as the binding force to bring collaboration amongst startup geniuses to solve one big problem.</li>
        <li>We have created a reliable position in the innovation sector over the years with multiple renowned conglomerates.</li>
        <li>We are partnered with several multinational companies that seek to integrate startups capable enough to solve problem statements for them.</li>
       </ul>
        </div>
      </div>
      
    </div>
    </div>
  </>
  )
}


export default CorporateInnovation;