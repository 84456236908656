import React, { useState } from 'react';
import { toast } from 'react-toastify';

const CFOServicesform = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    organization: '',
    designation: '',
    company_website: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/cfoservice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success('Form submitted successfully!');
        console.log(result);
        onClose();
      } else {
        const errorResult = await response.json();
        toast.error('Error submitting form: ' + errorResult.message);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error submitting form. Please try again.');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <h3>CFO Services with Ideas to Impacts Hub</h3>
            <p>
              Virtual CFO services are a cost-effective alternative to hiring a full-time CFO, providing customized financial advice and support for small and medium-sized businesses.
              This enables them to focus on their core competencies while managing finances, developing growth strategies, ensuring compliance with regulations, and receiving financial insights and analysis.
            </p>
            <div className="mb-3">
              <label className="form-label">Name <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email <span className='star'>*</span></label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Contact Number <span className='star'>*</span></label>
              <input
                type="number"
                className="form-control"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Enter contact number"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Organization <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                placeholder="Organization Name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Designation <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                placeholder="Your Designation"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Company Website <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="company_website"
                value={formData.company_website}
                onChange={handleChange}
                placeholder="Company Website"
                required
              />
            </div>
            <hr />
            <div className='mb-3'>
              <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CFOServicesform;
