import React from 'react'
import './Home.css'
import Heading from '../../commoncomponents/Heading/Heading'
import homeImg from '../../assets/images/home/section1/image-1.svg'
import homeImg2 from '../../assets/images/home/image-2.png'
import homeImg3 from '../../assets/images/home/image-3.png'
import homeImg4 from '../../assets/images/home/image-4.png'
import locationImg from '../../assets/images/home/SIcon-1.png'
import Ecosystem1 from '../../assets/images/home/EIcon-1.png'
import Ecosystem2 from '../../assets/images/home/EIcon-2.png'
import Ecosystem3 from '../../assets/images/home/EIcon-3.png'

import stratUpIcon1 from '../../assets/images/home/section3/Icons/icon-1.svg'
import stratUpIcon2 from '../../assets/images/home/section3/Icons/icon-2.svg'
import stratUpIcon3 from '../../assets/images/home/section3/Icons/icon-3.svg'
import stratUpIcon4 from '../../assets/images/home/section3/Icons/icon-4.svg'
import ArrowBtn from '../../commoncomponents/buttons/ArrowBtn'
import Successstories from './successstories'
import Currentprograms from './currentprograms'
import checkImg from '../../assets/images/check.png'
import Speakers from './Speakers'
import Partners from './Partners'
import hoemCorporateImg from '../../assets/images/home-corporate.png'
export const features = [
  "Complete Security with CCTV Surveillance",
  "24*7 Access to the Space/365 Days",
  "Reception Facility on the Ground floor",
  "Beautifully designed Co-working space",
  "Access to consultants & mentors",
  "Conference, meeting room and rooftop cafeteria",

];
export const Home = () => {
  return (
    <>
      <div className='container-fluid homecontainer'>
        <div className='container homepaddings'>
          <div className='row'>
            <div className='col-md-4 heading-home'>
              <h1 className='heading-main'>Nurturing <span className='heading-yellow'>Ideas</span> for <br />
                an <span className='heading-yellow'>Impactful</span> <br />
                Tomorrow!</h1>
            </div>
            <div className='col-md-8 image-home'>
              <img src={homeImg} className='img-fluid' alt='homeimg' />
            </div>
          </div>
        </div>
      </div>

      <Successstories />

      <div className='container startupContainer'>
        <div className='row g-4'>
          <div className='col-md-6'>
            <img src={homeImg2} className='img-main-start' alt='home-img' />
          </div>
          <div className='col-md-6 mt-5'>
            <h1 className='start-heading'>Ways to Boost your <span className='start-text'>Startup</span></h1>

            <div className='row row-cols-1 row-cols-md-2 g-4 pt-5'>
              <div class="col">
                <div className='py-3'>
                  <img src={stratUpIcon1} className='img-icon' alt='home-img-icon' />
                  <h5 className='startup-text'>Investment Program</h5>
                </div>
              </div>
              <div class="col">
                <div className='py-3'>
                  <img src={stratUpIcon2} className='img-icon2' alt='home-img-icon' />
                  <h5  className='startup-text'>Incubation Program</h5>
                </div>
              </div>
              <div class="col">
                <div className='py-3'>
                  <img src={stratUpIcon3} className='img-icon3' alt='home-img-icon' />
                  <h5  className='startup-text'>CFO Services</h5>
                </div>
              </div>
              <div class="col">
                <div className='py-3'>
                  <img src={stratUpIcon4} className='img-icon4' alt='home-img-icon' />
                  <h5  className='startup-text'>Membership Programs</h5>
                </div>
              </div>
              <div>
                <ArrowBtn title="Know more"  path="/startup"/>
              </div>
            </div>

          </div>
        </div>
      </div>


      <Currentprograms />

      <div className='container pb-5'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='py-5'>
              <h5 className='innovation-heading'>Venturing to Diverse Pathways of</h5>
              <h1 className='innovation-heading2'>Corporate Innovation</h1>
              <p className='inn-text'>Let us help you look for the hidden gems that fit your crown!</p>
            </div>
              <img className='img-fluid ' src={hoemCorporateImg} />
            <div className='mt-5 pb-4'>
              <ArrowBtn title="Know more" path="/corporate" />
            </div>
          </div>
          <div className='col-md-6'>
            <img src={homeImg3} className='img-innovation' alt='homeimg' />

          </div>
        </div>
      </div>


      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 className='facilities-heading-1'>Bringing our <span className='facilities-heading'>Facilities to Spaces</span>  </h3>
            <img src={homeImg4} className='img-Facilities' alt='homeimg' />
            <div className='py-5'>
              <h5 className='facilities-heading-1'>Be Center</h5>
              <p className='p-text'>Be a franchise owner of Ideas to Impacts Hub</p>
              <ArrowBtn title="Know more" path="/facility-space"/>
            </div>
          </div>
          <div className='col-md-6 full-screen-bg mt-5'>
            <h3 className='facilities-heading-1'>All our centers are primmed with facilities</h3>
            {features.map((feature, index) => (
              <div className='d-flex '>
                <img src={checkImg} alt='check' className='img-check' />
                <p key={index} className='ms-2 p-text'>{feature}</p>
              </div>
            ))}
            <div className='mt-3'>
              <h5 className='facilities-heading-1'>Our 4 centers</h5>
              <div className='d-flex align-items-center py-3'>
                <img src={locationImg} alt='locationImg' className='locationImg' />
                <p className='location-text ms-2'>Baner HQ</p>
              </div>
              <div className='d-flex align-items-center py-3'>
                <img src={locationImg} alt='locationImg' className='locationImg' />
                <p className='location-text ms-2'>Pan Card Club Road</p>
              </div>
              <div className='d-flex align-items-center py-3'>
                <img src={locationImg} alt='locationImg' className='locationImg' />
                <p className='location-text ms-2'>Wakad</p>
              </div>
              <div className='d-flex align-items-center py-3'>
                <img src={locationImg} alt='locationImg' className='locationImg' />
                <p className='location-text ms-2'>Viman Nagar</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid ecosystem-container'>
        <div className='container'>
          <h1 className='ecosystem-heading'>The <span style={{"color":"#F59E0A"}}>Eventful Ecosystem</span></h1>
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
            <img src={Ecosystem1} className='img-eco' alt='homeimg' />
                <h2 className='eco-card-heading'>Upcoming Events</h2>
                <p className='eco-card-text'>Discover opportunities with our events & be a part of the startup ecosystem</p>
            </div>
            <div className="col">
            <img src={Ecosystem2} className='img-eco' alt='homeimg' />
                <h2 className='eco-card-heading'>Host an Event</h2>
                <p className='eco-card-text'>Be the voice of change & motivate curious minds at ideas to impacts hub</p>
            </div>
            <div className="col">
            <img src={Ecosystem3} className='img-eco' alt='homeimg' />
                <h2 className='eco-card-heading'>Sponsor an Event</h2>
                <p className='eco-card-text'>Do you want to grow your brand awareness among innovative minds?</p>
            </div>
          </div>
          <div className='d-flex justify-content-center align-items-center mt-5'>
              <ArrowBtn title="Know more" path="/events"/>
          </div>
        </div>
      </div>

      <Speakers />
      <Partners />
    </>
  )
}
