import React, {useState} from 'react'
import './Startup.css'
import mainImg from '../../assets/images/startup/Image-1.svg'
import gardenImg  from '../../assets/images/startup/Image-4.svg'
import investmentImg from '../../assets/images/startup/Image-3.svg'
import scotingImg1 from '../../assets/images/startup/scouting/scout-1.svg'
import scotingImg2 from '../../assets/images/startup/scouting/scout-2.svg'
import scotingImg3 from '../../assets/images/startup/scouting/scout-3.svg'
import scotingImg4 from '../../assets/images/startup/scouting/scout-4.svg'
import scotingImg5 from '../../assets/images/startup/scouting/scout-5.svg'
import scotingImg6 from '../../assets/images/startup/scouting/scout-6.svg'
import Heading from '../../commoncomponents/Heading/Heading'
import ArrowBtn from '../../commoncomponents/buttons/ArrowBtn'
import Membershipprogram from './Membershipprogram'
import Members from './Members'
import GardenForm from './GardenForm'
import StartupForm from './StartupForm'
import Services from '../Corporate/Services'

export const Startup = () => {
  const [showModal, setShowModal] = useState(false);
  const [startUpModal, setStartUpModal] = useState(false);
  const hadlestartupOpen = () => {
    setStartUpModal(true)
  }
  const hadlestartupClose = () => {
    setStartUpModal(false)
  }

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  return (
    <>
      <div className='container-fluid startup-container py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <img src={mainImg} alt='main-img' className='img-fluid' />
            </div>
            <div className='col-md-6 d-flex justify-content-center align-items-center'>
              <h1 className='startup-heading'>Join Our Family to <br /> Unlock Potential</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid pt-5'>
        <Heading title="Incubation Program" />
        <div className='bg-image'>
          <div className='container'>
            <div className='overlay-heading'>
              <h2 className='startup-heading'>Ideas to Impacts Hub helps you sow, water, and trim your startup plant</h2>
              <p className='startup-text'>Ideas to Impacts Hub's incubation program fosters the growth of startups, guiding them to the next level. We assist these startups in securing funding, gaining access to the market, and creating the ideal environment for their overall development. Additionally, they receive valuable mentorship from industry experts.</p>
              <p className='startup-text'>We have a discerning approach when it comes to selecting startups for our incubation program. We invite you to share your pitch and take a chance to be considered for our program.</p>
              <button
                            onClick={hadlestartupOpen}
                                style={{
                                    fontFamily: '"Inter Tight", sans-serif',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#F59E0B',
                                    border: 'none',
                                    borderRadius: '9999px',
                                    fontWeight: 'bold',
                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                    width: '160px'
                                }}
                            >Apply Now
                                <span style={{ fontSize: '30px' }}>  &#8594;</span>
                            </button>
                                
            </div>
          </div>
        </div>
      </div>
      <StartupForm  show={startUpModal}  onClose={hadlestartupClose} />
      <div className='container-fluid investment-container pt-5'>
          <Heading  title="Investment Programmes"/>
          <div className='container py-5'>
              <div className='row'>
                  <div className='col-md-6'>
                    <h2 className='startup-heading'>SISF (Startup India Seed Fund Scheme)</h2>
                    <p className='startup-text-2'>Get financial assistance for your early-stage startup</p>
                    <p className='startup-text'>We understand the hurdles an innovative startup faces that may stop them in the tracks due to a lack of the initial capital. That's where Ideas to Impacts Hub steps in to help you take the leap.
Ideas to Impacts Hub is a DPIIT-recognized incubator for the disbursement of Startup India Seed Fund to promising startups.
We have positioned ourselves to turn a brilliant idea into an epochal impact with each funding valued up to 50 Lakh INR*.</p>

                    <p className='startup-text-3'>Check if your startup is eligible for the SISFS Here</p>
                  </div>
                  <div className='col-md-6'>
                    <img  src={investmentImg} className='img-fluid'/>
                  </div>
              </div>
          </div>
      </div>
      <div className='container-fluid'>
          <div className='bg-image-Scouting'>
              <div className='container py-5'>
                <Heading  title="Startup Scouting"/>
                <p className='Scouting-first-text'>We're looking for the next big tech revolution</p>
                <div className='row row-cols-1 row-cols-md-3 g-4 pt-5 py-5'>
                  <div className='col'>
                     <div className='scout-card'>
                        <div className='d-flex align-items-center'>
                            <img src={scotingImg1} alt='scouting-img' />
                            <h3 className='ms-3 scouting-text'>Sports Tech</h3>
                        </div>
                     </div>
                  </div>
                  <div className='col'>
                     <div className='scout-card'>
                        <div className='d-flex align-items-center'>
                            <img src={scotingImg2} alt='scouting-img' />
                            <h3 className='ms-3 scouting-text'>Deep Tech</h3>
                        </div>
                     </div>
                  </div>
                  <div className='col'>
                     <div className='scout-card'>
                        <div className='d-flex align-items-center'>
                            <img src={scotingImg3} alt='scouting-img' />
                            <h3 className='ms-3 scouting-text'>Health Tech</h3>
                        </div>
                     </div>
                  </div>
                  <div className='col'>
                     <div className='scout-card'>
                        <div className='d-flex align-items-center'>
                            <img src={scotingImg4} alt='scouting-img' />
                            <h3 className='ms-3 scouting-text'>FinTech</h3>
                        </div>
                     </div>
                  </div>
                  <div className='col'>
                     <div className='scout-card'>
                        <div className='d-flex align-items-center'>
                            <img src={scotingImg5} alt='scouting-img' />
                            <h3 className='ms-3 scouting-text'>Mobility Tech</h3>
                        </div>
                     </div>
                  </div>
                  <div className='col'>
                     <div className='scout-card'>
                        <div className='d-flex align-items-center'>
                            <img src={scotingImg6} alt='scouting-img' />
                            <h3 className='ms-3'>Green Tech</h3>
                        </div>
                     </div>
                  </div>
                </div>
              </div>  
          </div>
      </div>

      <div className='container-fluid investment-container'>
        <div className='container py-5'>
          <div className='row'>
              <div className='col-md-6'>
            <h2 className='startup-heading'>Enterprise Garden for Startups</h2>
            <p className='startup-text-2'>Be the joining link for mega corps with your SAP BTP solutions</p>
              <p className='startup-text'>Ideas to Impacts Hub and Primus Techsystems have come together to build Enterprise Garden- a curated platform that enables startups to adopt the SAP BTP Platform to serve a broader customer base, providing them the tools and support they need to succeed.</p>
             
              <div className='mt-5'>
              <button
                            onClick={handleOpenModal}
                                style={{
                                    fontFamily: '"Inter Tight", sans-serif',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#F59E0B',
                                    border: 'none',
                                    borderRadius: '9999px',
                                    fontWeight: 'bold',
                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                    width: '160px'
                                }}
                            >Apply Now
                                <span style={{ fontSize: '30px' }}>  &#8594;</span>
                            </button>
              </div>
              <GardenForm show={showModal}  onClose={handleCloseModal}/>
              </div>
              <div className='col-md-6'>
                <img src={gardenImg} alt='gardenImg' className='img-fluid'/>
              </div>
          </div>
        </div>
      </div>
      <Services />
      <Membershipprogram />
      <Members />
    </>
  )
}
