import React from 'react'
import './Corporate.css'
import mainImg from '../../assets/images/corporate/image-1.svg'
import highlightImg1 from '../../assets/images/corporate/highlights/highlight-1.svg'
import highlightImg2 from '../../assets/images/corporate/highlights/highlight-2.svg'
import highlightImg3 from '../../assets/images/corporate/highlights/highlight-3.svg'
import Heding from '../../commoncomponents/Heading/Heading'
import probleImg from '../../assets/images/corporate/image-2.png'
import Services from './Services'
import CorporateInnovation from './CorporateInnovation'
import EnterpriseGarden from './EnterpriseGarden'
import PastRecords from './PastRecords'
import Benefit from './Benefit'
export const Corporate = () => {
  return (
    <>
      <div className='container-fluid custom-dual-bg'>
        <div className='d-flex justify-content-center align-items-center h-100'>
          <div className='container py-5'>
            <div className='row'>
              <div className='col-md-6'>
                <img src={mainImg} alt='main-img' className='img-Corporate' />
              </div>
              <div className='col-md-4 d-flex align-items-center'>
                <h1 className='corporate-heading'>Find Innovative answers to the restricting problems.</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-5'>
        <Heding title="Keys Highlights" />
        <p className='Branding-text'>The impact we cause to aid your boom</p>
        <div class="row row-cols-1 row-cols-md-3 g-4 py-5">
          <div class="col">
            <div className='Highlights-card'>
              <img src={highlightImg1} alt='main-img' className='img-hight-corporate' />
              <h3 className='highlight-card-heading'>Branding & Talent Sourcing</h3>
              <p className='highlight-card-text'>Build a strong brand and attract top performers to fuel your success with our help.</p>
            </div>
          </div>
          <div class="col">
            <div className='Highlights-card'>
              <img src={highlightImg2} alt='main-img' className='img-hight-corporate' />

              <h3 className='highlight-card-heading'>Co-Innovation & Collaboration</h3>
              <p className='highlight-card-text'>Spark groundbreaking ideas by collaborating within and beyond your network to innovate.</p>
            </div>
          </div>
          <div class="col">
            <div className='Highlights-card'>
              <img src={highlightImg3} alt='main-img' className='img-hight-corporate' />

              <h3 className='highlight-card-heading'>Branding & Talent Sourcing</h3>
              <p className='highlight-card-text'>Unlock disruptive strategies and significant revenue growth through targeted markets and investments.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='container-fluid'>
        <div className='row g-0'>
          <div className='col-md-7 first-bg-img d-flex align-items-center justify-content-center' >
            <h1 className="traditional-heading">Find non-traditional <br /> answers to the <br /> restricting problems.</h1>
          </div>
          <div className='col-md-5 bg-primary'>
            <img src={probleImg} alt='p-img' className='img-bg-corporate' />
          </div>
        </div>
      </div> */}
      <Benefit />
      <CorporateInnovation />
      <EnterpriseGarden />
      <PastRecords />
    </>
  )
}
